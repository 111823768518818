import * as React from 'react';

export function ConfluenceIcon(props) {
  return (
    React.createElement('svg', {
      viewBox: "0 0 32 32"   ,
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg",
      ...props,}
    
      , React.createElement('path', {
        d: "M4.873 21.557c-.254.399-.544.87-.761 1.233a.765.765 0 00.253 1.052l4.966 3.046a.764.764 0 001.051-.254c.181-.326.435-.761.725-1.233 1.957-3.227 3.95-2.865 7.503-1.16l4.929 2.321a.77.77 0 001.015-.363l2.356-5.331c.181-.363 0-.834-.363-1.016a152.694 152.694 0 01-4.929-2.357c-6.705-3.228-12.396-3.01-16.745 4.062z"                   ,
        fill: "url(#paint0_linear)",}
      )
      , React.createElement('path', {
        d: "M27.127 9.443a59.1 59.1 0 00.762-1.233.765.765 0 00-.254-1.052l-4.966-3.046a.764.764 0 00-1.05.254c-.182.326-.436.761-.726 1.233-1.957 3.228-3.95 2.865-7.503 1.16L8.461 4.44a.77.77 0 00-1.015.362L5.09 10.132c-.18.363 0 .834.363 1.016 1.05.471 3.117 1.45 4.93 2.357 6.704 3.192 12.395 3.01 16.744-4.062z"                             ,
        fill: "url(#paint1_linear)",}
      )
      , React.createElement('defs', null
        , React.createElement('linearGradient', {
          id: "paint0_linear",
          x1: 26.7906,
          y1: 28.4674,
          x2: 11.7924,
          y2: 19.8546,
          gradientUnits: "userSpaceOnUse",}
        
          , React.createElement('stop', { stopColor: "#0052CC",} )
          , React.createElement('stop', { offset: 0.9182, stopColor: "#2380FB",} )
          , React.createElement('stop', { offset: 1, stopColor: "#2684FF",} )
        )
        , React.createElement('linearGradient', {
          id: "paint1_linear",
          x1: 5.20945,
          y1: 2.52323,
          x2: 20.2079,
          y2: 11.1361,
          gradientUnits: "userSpaceOnUse",}
        
          , React.createElement('stop', { stopColor: "#0052CC",} )
          , React.createElement('stop', { offset: 0.9182, stopColor: "#2380FB",} )
          , React.createElement('stop', { offset: 1, stopColor: "#2684FF",} )
        )
      )
    )
  );
}
