import * as React from 'react';

export function GitLabIcon(props) {
  return (
    React.createElement('svg', {
      xmlns: "http://www.w3.org/2000/svg",
      x: "0px",
      y: "0px",
      viewBox: "0 0 586 559"   ,
      ...props,}
    
      , React.createElement('style', null
        , 
          '.st3{fill:#e24329}.st4{fill:#fca326}.st5{fill:#fc6d26}.st15{opacity:.1;fill:#e828e3}.st15,.st16{display:inline}.st18{font-family:&apos;SourceSansPro-Semibold&apos;}.st19{font-size:24px}'
        
      )
      , React.createElement('g', { id: "logo_art",}
        , React.createElement('path', {
          id: "path50_2_",
          className: "st3",
          d: "M293.026 434.983l62.199-191.322H230.918l62.108 191.322z"  ,}
        )
        , React.createElement('path', {
          id: "path66_6_",
          className: "st4",
          d: "M143.798 243.662l-18.941 58.126c-1.714 5.278.137 11.104 4.661 14.394l163.509 118.801-149.229-191.321z"       ,}
        )
        , React.createElement('path', {
          id: "path74_2_",
          className: "st3",
          d: "M143.798 243.662h87.12l-37.494-115.224c-1.919-5.895-10.282-5.895-12.27 0l-37.356 115.224z"   ,}
        )
        , React.createElement('path', {
          id: "path82_6_",
          className: "st4",
          d: "M442.346 243.662l18.873 58.126c1.714 5.278-.137 11.104-4.661 14.394L293.026 434.983l149.32-191.321z"      ,}
        )
        , React.createElement('path', {
          id: "path86_2_",
          className: "st3",
          d: "M442.346 243.662h-87.12l37.425-115.224c1.919-5.895 10.282-5.895 12.27 0l37.425 115.224z"     ,}
        )
        , React.createElement('path', {
          className: "st5",
          d: "M293.026 434.983L355.225 243.662 442.346 243.662z"    ,}
        )
        , React.createElement('path', {
          className: "st5",
          d: "M293.026 434.983L143.798 243.662 230.918 243.662z"    ,}
        )
      )
    )
  );
}
