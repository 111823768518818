/* eslint-disable no-console */
import * as React from 'react';
import { ActionProvider } from '@internal/plugin-dev-portal';
import { useCreateGitlabUser } from '@internal/plugin-gitlab-integration';
import { useCreateJiraUser } from '@internal/plugin-jira-integration';

export const AccountManagementActionsProvider = ({
  children,
}

) => {
  const { createAccount: createGitlabAccount } = useCreateGitlabUser();
  const { createAccount: createJiraAccount } = useCreateJiraUser();

  return (
    React.createElement(ActionProvider, {
      action: {
        createGitlabAccount: createGitlabAccount,
        createJiraAccount: createJiraAccount,
      },}
    
      , children
    )
  );
};
