import { InfoCard } from '@backstage/core-components';
import { ComponentAccordion, HomePageToolkit } from '@backstage/plugin-home';
import * as React from 'react';
import GitHubIcon from '@material-ui/icons/GitHub';
import {
  ArgoCDIcon,
  ConfluenceIcon,
  GitLabIcon,
  JiraIcon,
} from '../../components/icon';

export function ShortcutsWidget() {
  const ExpandedComponentAccordion = (props) => (
    React.createElement(ComponentAccordion, { expanded: true, ...props,} )
  );

  return (
    React.createElement(InfoCard, {
      title: "Shortcuts",
      subheader: "Quick access to important tools."    ,
      noPadding: true,}
    
      , React.createElement(HomePageToolkit, {
        title: "Essentials",
        tools: [
          {
            url: 'https://gitlab.warungpintar.co/',
            label: 'GitLab',
            icon: React.createElement(GitLabIcon, { width: 40, height: 40,} ),
          },
          {
            url: 'https://github.com/warungpintar',
            label: 'GitHub',
            icon: React.createElement(GitHubIcon, { width: 40, height: 40,} ),
          },
          {
            url: 'https://warungpintargroup.atlassian.net/jira',
            label: 'Jira',
            icon: React.createElement(JiraIcon, { width: 40, height: 40,} ),
          },
          {
            url: 'https://warungpintargroup.atlassian.net/wiki/home',
            label: 'Confluence',
            icon: React.createElement(ConfluenceIcon, { width: 40, height: 40,} ),
          },
        ],
        Renderer: ExpandedComponentAccordion,}
      )
      , React.createElement(HomePageToolkit, {
        title: "DevOps",
        tools: [
          {
            url: 'https://argocd-staging.warungpintar.co/',
            label: 'ArgoCD (Staging)',
            icon: React.createElement(ArgoCDIcon, { width: 40, height: 40,} ),
          },
          {
            url: 'https://argocd.warungpintar.co/',
            label: 'ArgoCD (Production)',
            icon: React.createElement(ArgoCDIcon, { width: 40, height: 40,} ),
          },
        ],
        Renderer: ComponentAccordion,}
      )
    )
  );
}
