import { Content, Page } from '@backstage/core-components';
import { Grid, makeStyles } from '@material-ui/core';
import * as React from 'react';
import { HomePageHeader } from './components/home-page-header';
import { ShortcutsWidget } from './widgets/shortcuts';
import { TechRadarWidget } from './widgets/tech-radar';

const useLogoStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(5, 0),
  },
}));

export const HomePage = () => {
  // TODO:
  // - Compose Dashboard layouts
  // - Account Management widget
  return (
    React.createElement(Page, { themeId: "home",}
      , React.createElement(HomePageHeader, null )
      , React.createElement(Content, null
        , React.createElement(Grid, { container: true,}
          , React.createElement(Grid, { item: true, xs: 12, md: 6,}
            , React.createElement(ShortcutsWidget, null )
          )
          , React.createElement(Grid, { item: true, xs: 12, md: 6,}
            , React.createElement(TechRadarWidget, null )
          )
        )
      )
    )
  );
};
