import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {

  configApiRef,
  createApiFactory,
  identityApiRef,
} from '@backstage/core-plugin-api';
import {
  TechRadarApiClient,
  techRadarApiRef,
} from '@internal/plugin-tech-radar';

export const apis = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  ScmAuth.createDefaultApiFactory(),
  createApiFactory({
    api: techRadarApiRef,
    deps: { configApi: configApiRef, identityApi: identityApiRef },
    factory: ({ configApi, identityApi }) =>
      new TechRadarApiClient(configApi, identityApi),
  }),
];
