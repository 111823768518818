import { createApp } from '@backstage/app-defaults';
import { FeatureFlagged, FlatRoutes } from '@backstage/core-app-api';
import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,
} from '@backstage/core-components';
import { googleAuthApiRef } from '@backstage/core-plugin-api';
import { apiDocsPlugin } from '@backstage/plugin-api-docs';
import { catalogPlugin } from '@backstage/plugin-catalog';
import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { catalogImportPlugin } from '@backstage/plugin-catalog-import';
import { orgPlugin } from '@backstage/plugin-org';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { SearchPage } from '@backstage/plugin-search';
import { techdocsPlugin } from '@backstage/plugin-techdocs';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import {
  DevPortalDetailPage,
  DevPortalPage,
  DevPortalUserWizardPage,
} from '@internal/plugin-dev-portal';
import { TechRadarPage } from '@internal/plugin-tech-radar';
import React from 'react';
import { Route } from 'react-router';
import { apis } from './apis';
import { Root } from './components/Root';
import { searchPage } from './modules/search/SearchPage';
import { AccountManagementActionsProvider } from './modules/account';
import { HomePage } from './modules/home';
import * as plugins from './plugins';
import {
  ImageUploaderContainer,
  ImageUploader,
  EditorScreen,
} from '@internal/plugin-image-uploader';

const app = createApp({
  apis,
  plugins: Object.values(plugins),
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  components: {
    SignInPage: (props) => {
      return (
        React.createElement(SignInPage, {
          ...props,
          providers: [
            'guest',
            {
              id: 'google-auth-provider',
              title: 'Google',
              message: 'Sign In using Google',
              apiRef: googleAuthApiRef,
            },
          ],
          title: "Select a sign-in method"   ,
          align: "center",}
        )
      );
    },
  },
});

const AppProvider = app.getProvider() 

;
const AppRouter = app.getRouter() 

;

const routes = (
  React.createElement(FlatRoutes, null
    , React.createElement(Route, { path: "/", element: React.createElement(HomepageCompositionRoot, null ),}
      , React.createElement(HomePage, null )
    )
    , React.createElement(Route, { path: "/search", element: React.createElement(SearchPage, null ),}
      , searchPage
    )
    , React.createElement(Route, { path: "/dev-portal", element: React.createElement(DevPortalPage, null ),} )
    , React.createElement(Route, {
      path: "/dev-portal/:email",
      element: 
        React.createElement(AccountManagementActionsProvider, null
          , React.createElement(Route, { path: "/dev-portal/:email", element: React.createElement(DevPortalDetailPage, null ),} )
        )
      ,}
    )
    , React.createElement(Route, { path: "/dev-portal/add-user", element: React.createElement(DevPortalUserWizardPage, null ),} )

    , React.createElement(FeatureFlagged, { with: "image-uploader-enabled",}
      , React.createElement(Route, {
        path: "/image-uploader/:identity",
        element: React.createElement(ImageUploaderContainer, null ),}
      
        , React.createElement(ImageUploader, null )
        , React.createElement(EditorScreen, null )
      )
    )
    , React.createElement(Route, { path: "/settings", element: React.createElement(UserSettingsPage, null ),} )
    , React.createElement(Route, {
      path: "/tech-radar",
      element: 
        React.createElement(TechRadarPage, {
          pageTitle: "Warung Pintar Technology Radar"   ,
          width: 1500,
          height: 800,}
        )
      ,}
    )
  )
);

const App = () => (
  React.createElement(AppProvider, null
    , React.createElement(AlertDisplay, null )
    , React.createElement(OAuthRequestDialog, null )
    , React.createElement(AppRouter, null
      , React.createElement(Root, null, routes)
    )
  )
);

export default App;
