import { InfoCard } from '@backstage/core-components';
import { Typography } from '@material-ui/core';
import * as React from 'react';

const linkInfo = { title: 'Go to Tech Radar', link: '/tech-radar' };

export function TechRadarWidget() {
  return (
    React.createElement(InfoCard, { title: "Tech Radar" , deepLink: linkInfo,}
      , React.createElement(Typography, { variant: "body1", component: "p",}, "A visual guide to the tools and technology stack that we use at Warung Pintar."


      )
    )
  );
}
