import * as React from 'react';

export function JiraIcon(props) {
  return (
    React.createElement('svg', {
      viewBox: "0 0 32 32"   ,
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg",
      ...props,}
    
      , React.createElement('path', {
        d: "M26.914 15.337L16.572 4.994 15.577 4 7.82 11.757l-3.547 3.547c-.364.364-.364.961 0 1.359l7.094 7.094L15.577 28l7.757-7.757.133-.133 3.447-3.447a.94.94 0 000-1.326zm-11.337 4.21L12.03 16l3.547-3.547L19.124 16l-3.547 3.547z"                  ,
        fill: "#2684FF",}
      )
      , React.createElement('path', {
        d: "M15.577 12.453c-2.32-2.32-2.32-6.1-.033-8.42L7.787 11.79l4.21 4.21 3.58-3.547z"    ,
        fill: "url(#paint0_linear)",}
      )
      , React.createElement('path', {
        d: "M19.124 16l-3.547 3.547c2.32 2.32 2.32 6.1 0 8.453l7.79-7.79L19.124 16z"        ,
        fill: "url(#paint1_linear)",}
      )
      , React.createElement('defs', null
        , React.createElement('linearGradient', {
          id: "paint0_linear",
          x1: 14.9447,
          y1: 8.85952,
          x2: 9.99111,
          y2: 13.8131,
          gradientUnits: "userSpaceOnUse",}
        
          , React.createElement('stop', { offset: 0.176, stopColor: "#0052CC",} )
          , React.createElement('stop', { offset: 1, stopColor: "#2684FF",} )
        )
        , React.createElement('linearGradient', {
          id: "paint1_linear",
          x1: 16.2558,
          y1: 23.0892,
          x2: 21.1995,
          y2: 18.1456,
          gradientUnits: "userSpaceOnUse",}
        
          , React.createElement('stop', { offset: 0.176, stopColor: "#0052CC",} )
          , React.createElement('stop', { offset: 1, stopColor: "#2684FF",} )
        )
      )
    )
  );
}
