import * as React from 'react';

export function ArgoCDIcon(props) {
  return (
    React.createElement('svg', { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 360 360"   , ...props,}
      , React.createElement('defs', null
        , React.createElement('style', null
          , 
            '.cls-2{fill:#e9654b}.cls-3{fill:#b6cfea}.cls-6{fill:#ee794b}.cls-12{fill:#010101}.cls-13{fill:#fff}'
          
        )
        , React.createElement('clipPath', { id: "clip-path",}
          , React.createElement('path', {
            fill: "none",
            d: "M241.019 115.687L233.846 279.479 126.245 279.479 120.267 115.687"      ,}
          )
        )
      )
      , React.createElement('path', {
        className: "cls-2",
        d: "M123.854 251.982s-2.391 7.173-5.978 10.76a10.854 10.854 0 01-8.369 3.586 138.606 138.606 0 01-17.933 3.587s8.369 1.196 17.933 2.391c3.587 0 3.587 0 5.978 1.196 5.978 0 8.37-3.587 8.37-3.587zM233.846 251.982s2.391 7.173 5.978 10.76a10.854 10.854 0 008.369 3.586 138.606 138.606 0 0017.933 3.587s-8.369 1.196-19.129 2.391c-3.587 0-3.587 0-5.978 1.196-7.173 0-8.369-3.587-8.369-3.587z"                                           ,}
      )
      , React.createElement('circle', { className: "cls-3", cx: 180.04557, cy: 133.6208, r: 129.1208,} )
      , React.createElement('circle', { cx: 180.04557, cy: 133.6208, r: 124.33855, fill: "#e6f5f8",} )
      , React.createElement('circle', { cx: 180.04557, cy: 136.01194, r: 101.62285, fill: "#d0e8f0",} )
      , React.createElement('g', { id: "Body",}
        , React.createElement('path', {
          className: "cls-6",
          d: "M120.267 183.834s8.37 135.1 8.37 137.49c0 1.196 1.195 3.587-4.783 5.978s-25.107 7.173-25.107 7.173h28.694c13.151 0 13.151-10.76 13.151-13.15s3.587-53.801 3.587-53.801 1.195 60.974 1.195 63.365-1.195 5.978-9.564 8.369l-23.912 4.782h27.498c16.738 0 16.738-10.76 16.738-10.76l3.587-53.8s1.196 53.8 1.196 59.778c0 4.782-3.587 8.369-16.738 10.76-8.37 2.39-19.13 4.782-19.13 4.782h31.085c15.543-1.195 17.934-11.956 17.934-11.956l26.302-133.903z"                                   ,}
        )
        , React.createElement('path', {
          className: "cls-6",
          d: "M239.824 183.834s-8.37 135.1-8.37 137.49c0 1.196-1.195 3.587 4.783 5.978s25.107 7.173 25.107 7.173H232.65c-13.15 0-13.15-10.76-13.15-13.15s-3.588-53.801-3.588-53.801-1.195 60.974-1.195 63.365 1.195 5.978 9.564 8.369l23.912 4.782h-27.498c-16.738 0-16.738-10.76-16.738-10.76l-3.587-53.8s-1.195 53.8-1.195 59.778c0 4.782 3.586 8.369 16.737 10.76 8.37 2.39 19.13 4.782 19.13 4.782h-31.085c-15.543-1.195-17.934-11.956-17.934-11.956l-26.302-133.903zM241.02 118.079c0 33.475-27.499 59.778-59.779 59.778s-59.778-27.498-59.778-59.778S148.961 58.3 181.241 58.3s59.778 26.303 59.778 59.779z"                                          ,}
        )
        , React.createElement('g', { clipPath: "url(#clip-path)",}
          , React.createElement('circle', { className: "cls-6", cx: 181.24112, cy: 150.3587, r: 106.4051,} )
        )
        , React.createElement('path', {
          d: "M237.433 183.834s7.173-63.364-2.392-87.276C217.108 54.714 175.263 58.3 175.263 58.3s23.912 9.565 25.107 45.432c1.196 25.107 0 62.169 0 62.169z"              ,
          fill: "#e34e3b",
          opacity: 0.22,}
        )
      )
      , React.createElement('g', { id: "Face",}
        , React.createElement('circle', {
          cx: 156.13431,
          cy: 85.7983,
          r: 8.36894,
          fill: "#fbdfc3",
          opacity: 0.5,}
        )
        , React.createElement('path', {
          className: "cls-12",
          d: "M201.566 207.746c0 20.324-9.565 33.475-21.52 33.475s-21.52-15.542-21.52-35.866c0 0 9.564 19.129 22.715 19.129s20.325-16.738 20.325-16.738z"          ,}
        )
        , React.createElement('path', {
          className: "cls-13",
          d: "M201.566 207.746c0 13.15-9.565 20.324-21.52 20.324s-20.325-9.564-20.325-21.52c0 0 9.565 11.956 22.716 11.956s19.129-10.76 19.129-10.76z"          ,}
        )
        , React.createElement('circle', { className: "cls-2", cx: 221.89028, cy: 151.55425, r: 37.06246,} )
        , React.createElement('circle', { className: "cls-2", cx: 138.20087, cy: 151.55425, r: 37.06246,} )
        , React.createElement('circle', { className: "cls-13", cx: 221.89028, cy: 151.55425, r: 28.69351,} )
        , React.createElement('circle', { className: "cls-13", cx: 135.80973, cy: 151.55425, r: 28.69351,} )
        , React.createElement('circle', { className: "cls-12", cx: 135.80973, cy: 147.96757, r: 8.36895,} )
        , React.createElement('circle', { className: "cls-12", cx: 221.89028, cy: 147.96757, r: 8.36895,} )
      )
      , React.createElement('path', {
        className: "cls-3",
        d: "M48.534 165.901a5.648 5.648 0 01-5.978-5.978v-47.822a5.648 5.648 0 015.978-5.978 5.648 5.648 0 015.977 5.978v47.822a5.648 5.648 0 01-5.977 5.978zM309.166 165.901a5.648 5.648 0 01-5.977-5.978v-47.822a5.648 5.648 0 015.977-5.978 5.648 5.648 0 015.978 5.978v47.822a5.648 5.648 0 01-5.978 5.978z"                                ,}
      )
      , React.createElement('circle', { className: "cls-13", cx: 139.39641, cy: 37.97578, r: 5.97781,} )
      , React.createElement('path', {
        className: "cls-13",
        d: "M96.356 199.377a4.392 4.392 0 01-3.587-1.196c-14.346-17.933-21.52-40.649-21.52-63.365a106.683 106.683 0 0114.347-53.8 117.01 117.01 0 0138.258-38.258 5.257 5.257 0 015.978 1.196 5.257 5.257 0 01-1.196 5.977 96.496 96.496 0 00-49.018 83.69 94.222 94.222 0 0020.325 58.582c1.195 1.196 1.195 4.783-1.196 5.978-1.195 1.196-1.195 1.196-2.39 1.196z"                                      ,}
      )
      , React.createElement('path', {
        className: "cls-2",
        d: "M233.846 255.568v-9.564c-16.738 9.564-34.671 19.129-56.192 19.129-23.91 0-39.453-10.76-54.995-19.13l1.195 9.565s17.934 19.13 54.996 19.13c34.671-1.196 54.996-19.13 54.996-19.13z"           ,
        opacity: 0.25,}
      )
    )
  );
}
