import { Header, HeaderLabel } from '@backstage/core-components';
import { useUserProfile } from '@backstage/plugin-user-settings';
import * as React from 'react';

export const HomePageHeader = () => {
  const [date, setDate] = React.useState(undefined);
  const { displayName } = useUserProfile();
  const timeZoneLocale = 'en-GB';

  const defaultFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
  };

  React.useEffect(() => {
    setDate(new Date());
  }, []);

  return (
    React.createElement(Header, {
      title: `Welcome, ${displayName}!`,
      subtitle: "Start your developer journey here."    ,}
    
      , React.createElement(HeaderLabel, {
        label: "WIB",
        value: new Intl.DateTimeFormat(timeZoneLocale, {
          ...defaultFormatOptions,
          timeZone: 'Asia/Jakarta',
        }).format(date),}
      )
      , React.createElement(HeaderLabel, {
        label: "WITA",
        value: new Intl.DateTimeFormat(timeZoneLocale, {
          ...defaultFormatOptions,
          timeZone: 'Asia/Makassar',
        }).format(date),}
      )
      , React.createElement(HeaderLabel, {
        label: "WIT",
        value: new Intl.DateTimeFormat(timeZoneLocale, {
          ...defaultFormatOptions,
          timeZone: 'Asia/Jayapura',
        }).format(date),}
      )
    )
  );
};
